import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { device } from "../utils/breakpoints"
import { isMobile } from "react-device-detect"

const getBackground = graphql`
  query {
    default1: file(relativePath: { eq: "intro.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const StyledHero = ({ img, children, className, home }) => {
  const data = useStaticQuery(getBackground)
  return (
    <BackgroundImage
      fluid={img || data.default1.childImageSharp.fluid}
      className={className}
      home={home}
    >
      {children}
    </BackgroundImage>
  )
}

export default styled(StyledHero)`
  min-height: ${props => (props.home ? "calc(50vh);" : "30vh")};
  min-height: ${isMobile && "30vh"};
  background: ${props =>
    props.home ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.1)"};
  /* background: linear-gradient(rgba(51, 255, 102, 0.2), rgba(16, 123, 40, 0.2)); */
  background-position: center;

  background-size: cover;
  opacity: 1 !important;
  justify-content: flex-end;
  align-items: center;
  display: flex;

  @media ${device.tablet} {
    p {
      width: 70%;
    }
    .gatsby-image-wrapper {
      width: 300px;
    }
  }
`
